import React from "react"
import AffiliateLeadForm from "./affiliateLeadForm"
import "./contactUs.css"

const ContactUs = () => {
  return (
    <div className="contact-us">
      <h3 className="text-primary">Let's Chat</h3>
      <div>
        <div className="contact-use-header" style={{ margin: "16px 0px" }}>
          Upon receipt of your details, we’ll be in touch asap...chat soon.
        </div>
        <AffiliateLeadForm />
      </div>
    </div>
  )
}

export default ContactUs
